export const floorPlanLinks = [
  {
    to: "/floorplan/type-a1a",
    text: "2-BR Type A1a",
  },
  {
    to: "/floorplan/type-b2a",
    text: "2-BR Type B2a",
  },
  {
    to: "/floorplan/type-b3",
    text: "2-BR Type B3",
  },
  {
    to: "/floorplan/type-c2",
    text: "2-BR Type C2",
    subText: "(With virtual tour)",
  },
  {
    to: "/floorplan/type-d1",
    text: "3-BR Type D1",
  },
  {
    to: "/floorplan/type-d2",
    text: "3-BR Type D2",
  },
  {
    to: "/floorplan/type-e1",
    text: "3-BR Type E1",
  },
  {
    to: "/floorplan/type-e2",
    text: "3-BR Type E2",
    subText: "(With virtual tour)",
  },
  {
    to: "/floorplan/type-f1",
    text: "4-BR Type F1",
  },
  {
    to: "/floorplan/type-f2",
    text: "4-BR Type F2",
  },
  {
    to: "/floorplan/type-g",
    text: "4-BR Type G",
    subText: "(With virtual tour)",
  },
]
