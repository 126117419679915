import * as React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ImageView from "../components/imageView"
import { floorPlanLinks } from "../config/lookup"
import useWindowSize from "../hooks/useWindowSize"

interface Props {
  children: React.ReactNode
  title?: string
  tourLink?: string
}

const FloorplanView = (props: Props) => {
  const [showFull, setShowFull] = React.useState(false)
  const windowSize = useWindowSize()

  React.useEffect(() => {
    if (windowSize.width && windowSize.width > 768) {
      setShowFull(true)
    } else {
      setShowFull(false)
    }
  }, [windowSize.width])

  return (
    <Layout
      extra={
        <>
          {showFull && (
            <div className="fixed bottom-0 right-4 md:right-16 pb-8 z-40">
              <motion.ul className="fixed md:relative z-40 inset-0 flex flex-col justify-center items-center bg-primary md:bg-transparent backdrop-filter md:background-filter-none backdrop-blur-md md:backdrop-blur-none bg-opacity-70 md:bg-opacity-100 space-y-3 text-center text-base tracking-wide font-semibold uppercase font-semibold">
                {floorPlanLinks.map(v => (
                  <motion.li
                    key={v.to}
                    initial={{
                      y: 50,
                      opacity: 0,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: {
                        y: { stiffness: 1000, velocity: -100 },
                      },
                    }}
                    className="px-4 py-1 w-full hover:text-black/70 bg-cover md:bg-[#968f75] md:bg-[url('/images/bg.jpg')] hover:bg-none"
                  >
                    <Link to={v.to}>
                      {v.text}
                      {v.subText && (
                        <div
                          style={{
                            fontSize: "60%",
                            display: "block",
                            lineHeight: "8px",
                          }}
                        >
                          {v.subText}
                        </div>
                      )}
                    </Link>
                  </motion.li>
                ))}
                <motion.li
                  className="md:hidden"
                  onClick={() => setShowFull(false)}
                >
                  <svg
                    className="text-black/40"
                    height={16}
                    width={16}
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M443.6 387.1 312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z"
                    />
                  </svg>
                </motion.li>
              </motion.ul>
            </div>
          )}
          <div className="fixed bottom-0 right-4 md:right-16 pb-8">
            <motion.ul className="space-y-3 text-center text-xs md:text-base tracking-wide font-semibold uppercase font-semibold">
              {props.tourLink && (
                <motion.li
                  style={{ backgroundColor: "#968f75" }}
                  className="md:hidden px-4 py-1 hover:text-black/70 bg-cover bg-[url('/images/bg.jpg')] hover:bg-none"
                >
                  <Link to={props.tourLink}>Tour this unit</Link>
                </motion.li>
              )}
              <motion.li
                style={{ backgroundColor: "#968f75" }}
                className="md:hidden px-4 py-1 hover:text-black/70 bg-cover bg-[url('/images/bg.jpg')] hover:bg-none"
                onClick={() => setShowFull(!showFull)}
              >
                View Other Units
              </motion.li>
            </motion.ul>
          </div>
          {props.tourLink && (
            <div
              className="hidden md:block fixed bottom-32 left-1/2 z-20"
              style={{ transform: "translateX(-50%)" }}
            >
              <Link
                to={props.tourLink}
                style={{ backgroundColor: "#968f75" }}
                className="uppercase text-sm tracking-wide font-semibold px-8 py-2 hover:text-black/70 bg-cover bg-[url('/images/bg.jpg')] hover:bg-none"
              >
                Tour this unit
              </Link>
            </div>
          )}
        </>
      }
    >
      <Seo title={props.title} />
      <ImageView>{props.children}</ImageView>
    </Layout>
  )
}

export default FloorplanView
